<a class="cpt-link cpt-frosted-glass" *ngIf="app">
  <img
    class="cpt-avatar"
    alt="{{ app.display || '' | translate }}"
    [height]="size === IconSize.Large ? 64 : 48"
    [width]="size === IconSize.Large ? 64 : 48"
    [src]="app.icon"
  />
  <h3 *ngIf="size === IconSize.Large" class="cpt-header">
    {{ app.display || '' | translate }}
  </h3>
  <p *ngIf="size === IconSize.Large" class="cpt-description">
    {{ app.description || '' | translate }}
  </p>
</a>
