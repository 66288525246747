export enum App {
  Root = '',
  Home = 'home',
  Firefly = 'firefly',
  Alexandria = 'alexandria',
  Memories = 'memories',
  Art = 'art',
  Movies = 'movies',
  Resume = 'resume',
  Github = 'github',
  LinkedIn = 'linkedin',
}
